<script>
  import PowerBiReportWrapper from '@cox2m/city-services-ui-components/src/components/PowerBiReportWrapper.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import Unauthorized from '../modals/Unauthorized.svelte';

  import {
    getCookieByName,
    shouldDisplayUnauthorizedModal
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts, user} from '../stores';

  export let params;

  let pageName;
  let reportPages;

  const token = getCookieByName(`${'ENV'}_token`);

  let modalContext;


  const setPage = async (pageName) => {
    if(pageName && reportPages){
      const page = reportPages.find(page => page.displayName === pageName);
      page && (await page.setActive());
    }
  }

  const handleOnLoad = async (e) => {
    let report;

    if(e && e.detail.report){
      report = e.detail.report;
      reportPages = await report.getPages();
      setPage(pageName);
    }
  }

  $: pageName = decodeURI(params.page).replace('-', '/') || null;
  $: setPage(pageName);
</script>

<Modal bind:this={modalContext}>
  <MainScreen title="Home - Analytics" menuOpt={$menuOpts} user={$user}>
    <div slot="main-dashboard" class="h-100">
      <PowerBiReportWrapper
        reportId="66127bbd-2204-4f43-bb6c-6708af0be416"
        workspaceId="2a9d04e1-3229-4343-87ce-8519e3d93747"
        datasetIds="d0f8d2a2-1cae-4811-8ea2-268194b14f12"
        token={token}
        shouldDisplayUnauthorizedModal={() => shouldDisplayUnauthorizedModal(modalContext, Unauthorized)}
        enablePageNavigation={false}
        on:load={handleOnLoad}
      />
    </div>
  </MainScreen>
</Modal>
