import {getPowerBiReportPages, findByKeyRecursively} from '@cox2m/city-services-ui-components/src/funcs';

export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'water-metering',
    name: 'Water Metering',
    icon: 'droplet',
    shouldShow: true,
    subItems: [
      {
        key: 'analytics-overview',
        name: 'Overview',
        icon: 'dashboard',
        shouldShow: true
      },
      {
        key: 'devices',
        path: '/devices',
        name: 'Devices',
        icon: 'modules',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-hardware-installation-data')
      },
    ]
  },
  {
    key: 'monitoring',
    name: 'Monitoring',
    icon: 'chart-line',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-user-logs-by-appId'),
    subItems: [
      {
        key: 'Log',
        path: '/monitoring/log',
        name: 'Logs',
        icon: 'newspaper',
        shouldShow: true
      }
    ]
  },
  {
    key: 'alerts',
    path: '/alerts',
    name: 'Alerts+Notifications',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-notifications-metrics-by-app-id')
  },



  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];

export const buildMenuWithDynamicItems = async (user, TOKEN) => {
  let tempMenuOptions = staticMenuOptions(user);

  try {
    const response = await getPowerBiReportPages({
      workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747',
      reportId: '66127bbd-2204-4f43-bb6c-6708af0be416',
      token: TOKEN
    });

    let analytics = findByKeyRecursively({
      searchCriteria: "analytics-overview",
      array: tempMenuOptions || [],
    });

    analytics.subItems = response.value.map(page => ({
      key: `analytics-page-${page.order}`,
      path: `/analytics/${encodeURI(page.displayName).replace('/', '-')}`,
      name: page.displayName,
      icon: 'clipboard-text',
      shouldShow: true
    }));
  } catch (e) {
    console.error(e);
  }

  return tempMenuOptions;
};
