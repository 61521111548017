import MonitoringRedirect from './forms/monitoring/MonitoringRedirect.svelte';
import LogsWrapper from './forms/monitoring/LogsWrapper.svelte';
import AlertsWrapper from './forms/alerts/AlertsWrapper.svelte';
import DevicesScreen from './forms/DevicesScreen.svelte';
import Report from './forms/Report.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/analytics/:page?': Report,
  '/alerts': AlertsWrapper,
  '/devices': DevicesScreen,
  '/monitoring': MonitoringRedirect,
  '/monitoring/log': LogsWrapper,

  '*': NotFoundPage
};

export const openRoutes = []

export default routes
