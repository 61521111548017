import Api from 'cox_city_services_platform_api';

import { setErrorEventData } from '@cox2m/city-services-ui-components/src/funcs';

const {
  ApiClient,
  AuthApi,
  HardwareApi,
  DevicesApi
} = Api;

/**
 * Initialize the API client
 */
const initApiClient = () => {
  const apiClient = new ApiClient();
  apiClient.basePath = 'SMART_CITIES_API_HOST';
  apiClient.defaultHeaders = {
    'Access-Control-Allow-Origin': '*'
  };

  return apiClient;
};
const apiClient = initApiClient();

const authApi = new AuthApi(apiClient);
const hardwareApi = new HardwareApi(apiClient);
const devicesApi = new DevicesApi(apiClient);


/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = (token) => {
  apiClient.authentications["API_TOKEN"].apiKey = token;
};

/**
 * Handles a logout request.
 * @param {string} token
 */
export const logout = async (token) => {
  try {
    setToken(token);
    const response = await authApi.logout();
    return { fulfilled: true, ...response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'logout');
    return { fulfilled: false, ...error.body };
  }
};

/**
 * Retrieve hardware places
 * @param {string} token
 * @param {number} limit
 */
export const getHardwareLocations = async (token, opts) => {
  try {
    setToken(token);
    const response = await hardwareApi.getHardwarePlaces(opts);
    return { fulfilled: true, collection: response.map(place => ({ ...place })) };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-places');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
 * Retrieve hardware place by Id
 * @param {string} id
 */
export const getHardwarePlaceById = async (id) => {
  try {
    const response = await hardwareApi.getHardwarePlaceId(id);
    return { fulfilled: true, device: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-place-id');
    return { fulfilled: false, ...error.body };
  }
};

/**
 * Retrieve hardware place permit by Id
 * @param {string} id
 */
export const getHardwarePlacesPermit = async (id) => {
  try {
    const response = await hardwareApi.getHardwarePlacesPermit(id);
    return { fulfilled: true, permit: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-places-permit');
    return { fulfilled: false, ...error.body };
  }
};

/**
 * Saves hardware place permit by Id
 * @param {object} opts
 */
export const postHardwarePlacesPermit = async (opts) => {
  let options = {
    'hardwarePlacePermitDto': opts
  };
  try {
    const response = await hardwareApi.postHardwarePlacesPermit(options);
    return { fulfilled: true, permit: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'post-hardware-places-permit');
    return { fulfilled: false, ...error.body };
  }
};

/**
 * Retrieve hardware types
 */
export const getHardwareTypes = async (token) => {
  if (token) {
    setToken(token)
  }
  try {
    const response = await hardwareApi.getHardwareTypes();
    return { fulfilled: true, collection: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-types');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};


/**
 * Retrieve hardware devices
 * @param {string} token
 * @param {number} limit
 * @param {string} type
 */
export const getDevices = async (token, opts = { limit: 1000, type: undefined }) => {
  try {
    if (token) {
      setToken(token);
    }
    const response = await devicesApi.getDevices(opts);
    return { fulfilled: true, collection: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-devices');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
 * Retrieve hardware device by Id
 * @param {string} token
 * @param {string} id
 */
export const getDeviceById = async (token, id) => {
  try {
    setToken(token);
    const response = await hardwareApi.getDeviceId(id);
    return { fulfilled: true, device: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-device-id');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
 * Retrieve all the current hardware installations state history for all the devices
 * @param {string} token
 * @param {number} limit
 * @param {string} status
 * @param {object} opts
 */
export const getHardwareInstallationHistory = async (token, limit = 100, status = 'all') => {
  try {
    setToken(token);
    let opts = {
      'searchDeployments': "" // String | Search deployments information.
    };
    const response = await hardwareApi.getHardwareInstallationHistory(limit, status, opts);
    return { fulfilled: true, collection: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-installation-history');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
 * Get hardware installation history by id
 * @param {string} token
 * @param {string} id
 */
export const getHardwareInstallationHistoryById = async (id) => {
  try {
    const response = await hardwareApi.getHardwareInstallationIdHistory(id);
    return { fulfilled: true, device: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-installation-id-history');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
* Posts a hardware installation when doing a request deployment
* @param {string} hardwareId
* @param {string} hardwarePlaceId
*/
export const postHardwareInstallation = async (opts) => {
  try {
    const response = await hardwareApi.postHardwareInstallation({ 'hardwareInstallationDto': opts });
    return { fulfilled: true, response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'post-hardware-installation');
    return { fulfilled: false, ...error.body }
  }
}

/**
*  Get hardware installation data given a hardware type
* @param {string} token
* @param {object} opts
*/
export const getHardwareInstallationData = async (token, opts) => {
  try {
    setToken(token);
    const response = await hardwareApi.getHardwareInstallationData(opts);
    return { fulfilled: true, devices: response.installations};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-hardware-installation-data');
    return { fulfilled: false, ...error.body }
  }
}
